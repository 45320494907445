import React from 'react';
import './index.scss';
import Partner from './Partner';
import { IPartner } from '../../modules/models';

type Props = {
  partners: IPartner[];
  type: string;
};

const Partners: React.FC<Props> = (props) => {
  return (
    <div className="partners">
      {props.partners.map((partner, key) =>
        props.type === 'general' ? (
          <Partner
            key={key}
            logo={partner.logo_url}
            cover={partner.cover_url}
            description={partner.translation.about}
            url={partner.url}
            type={props.type}
          />
        ) : (
          <Partner
            key={key}
            logo={partner.logo_url}
            cover={partner.cover_url}
            description={partner.translation.about}
            url={partner.url}
            type={props.type}
          />
        )
      )}
    </div>
  );
};

export default Partners;
