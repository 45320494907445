import React, { useCallback, useEffect, useState, useContext } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import { useWindowDimensions } from '../../hooks/dimension';
import ImageCarousel from '../../components/ImageCarousel';
import Brands from '../../components/Brands';
import { Sidebar } from 'semantic-ui-react';
import SidebarDescription from '../../components/Sidebar';
import { AppContext } from '../../modules/contexts';
import { getBrands } from '../../modules/services';
import Dots from '../../components/Dots';
import Loader from '../../components/Layout/Loader';
// @ts-ignore
import { Fade } from 'react-reveal';
import './index.scss';

let flag = true;

const BrandsPage: React.FC = () => {
  const { width } = useWindowDimensions();
  const { state, dispatch } = useContext(AppContext);
  const [brands, setBrands] = useState({
    content: { title: '', text: '' },
    slides: [],
    brands: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const closeSidebar = () => {
    if (state.brandSidebar) {
      dispatch({ type: 'CLOSE_BRAND_SIDEBAR' });
    }
  };

  const getData = useCallback((id: number) => {
    getBrands(id).then((response: any) => {
      setBrands(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getData(0);
  }, [getData]);

  return (
    <>
      <Sidebar.Pushable>
        <Sidebar.Pusher dimmed={state.brandSidebar} onClick={closeSidebar}>
          <Header />
          <Navbar />
          <Loader loading={loading}>
            <ImageCarousel images={brands.slides} />
            <div className="brands-page relative">
              <div className="container">
                <Fade bottom>
                  <Title label={brands.content.title} position="none" />
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: brands.content.text }}
                  />
                </Fade>
                <Fade bottom>
                  <Brands
                    getData={getData}
                    brands={
                      width > 1224 ? brands.brands : brands.brands.slice(0, 10)
                    }
                  />
                </Fade>
              </div>
              <Dots
                row={4}
                column={5}
                color="#A6C4E5"
                opacity={1}
                top={128}
                left={27}
              />
              <Dots
                row={3}
                column={5}
                color="#A6C4E5"
                opacity={1}
                bottom={-53}
                right={37}
              />
            </div>
            <Footer />
          </Loader>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <SidebarDescription />
    </>
  );
};

export default BrandsPage;
