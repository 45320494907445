import React from 'react';
import VacancyBox from './VacancyBox';
import { IVacancy } from '../../modules/models';
import './index.scss';

type Props = {
  vacancies: IVacancy[];
};

const Vacancies: React.FC<Props> = (props) => {
  return (
    <div className="vacancies">
      {props.vacancies.map((item, key) => (
        <VacancyBox key={key} vacancy={item} />
      ))}
    </div>
  );
};

export default Vacancies;
