import React from 'react';
import { Image } from 'semantic-ui-react';
import './index.scss';
import { IVacancy } from '../../modules/models';
import moment from 'moment';
import { history } from '../../utils/history';
import { useTranslation } from 'react-i18next';

type Props = {
  vacancy: IVacancy;
};

const VacancyBox: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const goToVacancyPage = () => {
    history.push(`/vacancy/${props.vacancy.id}`);
  };

  return (
    <div className="vacancy" onClick={goToVacancyPage}>
      <h5 className="vacancy-deadline">
        {t('until', { time: moment(props.vacancy.deadline).format('LL') })}
      </h5>
      <h5 className="vacancy-postdate">
        {moment(props.vacancy.created_at).fromNow()}
      </h5>
      <h1 className="vacancy-position">{props.vacancy.translation?.title}</h1>
      <h3 className="vacancy-departmant">
        {props.vacancy.translation?.department}
      </h3>
      <div className="vacancy-button">
        <span>{t('vacancies')}</span>
        <div>
          <Image src="/assets/icons/careers/arrow-left.svg" />
        </div>
      </div>
    </div>
  );
};

export default VacancyBox;
