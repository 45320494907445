import React, { useContext } from 'react';
import { Image } from 'semantic-ui-react';
import { history } from '../../utils/history';
import { AppContext } from '../../modules/contexts';
import './index.scss';

type Props = {
  article?: any;
  brand?: any;
};

export const SearchArticle: React.FC<Props> = (props) => {
  const { dispatch } = useContext(AppContext);

  const goToArticlePage = () => {
    dispatch({ type: 'CLOSE_SEARCH_SIDEBAR' });
    dispatch({ type: 'CLOSE_MENU_SIDEBAR' });
    history.push(`/article/${props.article.slug}`);
  };

  return (
    <div className="search-result-item" onClick={goToArticlePage}>
      <Image
        className="search-image"
        alt="search"
        src={props.article.title_photo_url}
      />
      <h3 className="search-text">{props.article.translation.title}</h3>
    </div>
  );
};

export const SearchBrand: React.FC<Props> = (props) => {
  return (
    <div className="search-result-item">
      <Image className="search-image" alt="search" src={props.brand.logo_url} />
      <h3 className="search-text">
        Discounts on many winter-related products on the Bravo Market chain.
      </h3>
    </div>
  );
};
