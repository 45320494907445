import React from 'react';
import './index.scss';

type Props = {
  position: string;
  label: string;
  marginTop?: number;
};

const Title: React.FC<Props> = (props) => {
  return (
    <h1
      className={`title title-${props.position}-line`}
      style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
    >
      <span>{props.label}</span>
    </h1>
  );
};

export default Title;
