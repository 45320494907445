import React from 'react';
import { Image } from 'semantic-ui-react';
import { motion } from 'framer-motion';
import { history } from '../../utils/history';
import moment from 'moment';
import './index.scss';

type Props = {
  news: any;
  position: boolean;
};

const News: React.FC<Props> = (props) => {
  const goToArticlePage = () => {
    history.push(`/article/${props.news.slug}`);
  };

  return (
    <div className="news-box" onClick={goToArticlePage}>
      {props.position ? (
        <div className="relative">
          <Image
            className="news-image"
            alt="news"
            src={props.news.title_photo_url}
          />
          <h3 className="news-text">{props.news.translation?.title}</h3>
          <span className="news-date">
            {moment(props.news.created_at).format('LL')}
          </span>
          <motion.div
            className="white-div-top"
            animate={{ top: '100%', height: 0 }}
            transition={{ duration: 1 }}
          />
        </div>
      ) : (
        <div className="relative">
          <h3 className="news-text news-text-bottom">
            {props.news.translation?.title}
          </h3>
          <span className="news-date">12 Nov 2019</span>
          <Image
            className="news-image-bottom"
            alt="news"
            src={props.news.title_photo_url}
          />
          <motion.div
            className="white-div-top"
            animate={{ bottom: '100%', height: 0 }}
            transition={{ duration: 1 }}
          />
        </div>
      )}
    </div>
  );
};

export default News;
