import React from 'react';
import News from './News';
import { useWindowInnerDimensions } from '../../hooks/dimension';
import './index.scss';

type Props = {
  newsList: string[];
};

let position: boolean = false;

const NewsList: React.FC<Props> = (props) => {
  const { width } = useWindowInnerDimensions();

  return (
    <div className="container">
      <div className="news">
        {props.newsList.map((news, key) => {
          width > 1224 ? (position = !position) : (position = true);
          return <News key={key} news={news} position={position} />;
        })}
      </div>
    </div>
  );
};

export default NewsList;
