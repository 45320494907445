import { Settings, SettingsItem } from "../models";

const returnValue = (data: SettingsItem) => {
    return {
        key: data.key,
        value: data.value
    }
}

export const settingsReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'UPDATE_SETTINGS':
            let settings: Settings = state;
            settings = {
                facebook: returnValue(action.payload.settings.filter((item: any) => item.key === 'facebook')[0]),
                twitter: returnValue(action.payload.settings.filter((item: any) => item.key === 'twitter')[0]),
                linkedin: returnValue(action.payload.settings.filter((item: any) => item.key === 'linkedin')[0]),
                instagram: returnValue(action.payload.settings.filter((item: any) => item.key === 'instagram')[0]),
                headerPhone: returnValue(action.payload.settings.filter((item: any) => item.key === 'header_phone')[0]),
                headerAddress: returnValue(action.payload.settings.filter((item: any) => item.key === 'header_address')[0]),
                footerPhone: returnValue(action.payload.settings.filter((item: any) => item.key === 'footer_phones')[0]),
                footerAddress: returnValue(action.payload.settings.filter((item: any) => item.key === 'footer_address')[0]),
                footerEmail: returnValue(action.payload.settings.filter((item: any) => item.key === 'footer_emails')[0]),
                location: returnValue(action.payload.settings.filter((item: any) => item.key === 'location')[0])
            }
            return settings;
        default: return state;
    }
}
