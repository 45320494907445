import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ImageCarousel from '../../components/ImageCarousel';
import TextCarousel from '../../components/TextCarousel';
import Navbar from '../../components/Navbar';
import './index.scss';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';
import Button from '../../components/Button';
import { history } from '../../utils/history';
import { AppContext } from '../../modules/contexts';
import { getHome } from '../../modules/services';
import Dots from '../../components/Dots';
import { Sidebar } from 'semantic-ui-react';
import SidebarDescription from '../../components/Sidebar';
import Loader from '../../components/Layout/Loader';
// @ts-ignore
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import Brand from '../../components/Brands/Brand';

let flag = true;

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = React.useContext(AppContext);
  const [home, setHome] = useState({
    slides: [],
    news: [],
    partners: [],
    numbers: [],
    brands: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const getHomeData = useCallback(() => {
    getHome().then((response: any) => {
      setHome(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  const goToBrandsPage = () => {
    history.push('/brands');
  };

  const closeSidebar = () => {
    if (state.partnerSidebar) {
      dispatch({ type: 'CLOSE_PARTNER_SIDEBAR' });
    }
  };

  return (
    <>
      <SidebarDescription />
      <Sidebar.Pushable>
        <Sidebar.Pusher dimmed={state.brandSidebar} onClick={closeSidebar}>
          <Header />
          <Navbar />
          <Loader loading={loading}>
            <ImageCarousel images={home.slides} />
            <div className='relative'>
              <Fade bottom>
                <div className='home-statistics-section'>
                  <TextCarousel statistics={home.numbers} />
                </div>
              </Fade>
              <div className='home-partners-section'>
                <Fade bottom>
                  <Title label={t('brands')} position='left' />
                  <div className='container'>
                    <div className='brands'>
                      {home.brands.map((brand: any, key) => (
                        <Brand
                          key={key}
                          logo={brand.logo_url}
                          cover={brand.cover_url}
                          description={brand.translation.about}
                          url={brand.url}
                        />
                      ))}
                    </div>
                  </div>
                </Fade>
                <Fade bottom>
                  <Subtitle label={t('brands_description')} />
                </Fade>
                <Fade bottom>
                  <Button
                    label={t('see_all_brands')}
                    position='right'
                    onClick={goToBrandsPage}
                  />
                </Fade>
              </div>
              <Dots
                row={4}
                column={7}
                color='#3373B9'
                opacity={0.3}
                top={300}
                left={0}
              />
              <Dots
                row={5}
                column={10}
                color='#F2F2F2'
                opacity={1}
                bottom={0}
                right={0}
              />
            </div>
            <Footer />
          </Loader>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default HomePage;
