import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../hooks/dimension';
import Description from '../../components/Description';
import { Image, Form } from 'semantic-ui-react';
import { AppContext } from '../../modules/contexts';
import { sendMessage } from '../../modules/services';
import Dots from '../../components/Dots';
import { useAlert } from 'react-alert';
import './index.scss';
import { useTranslation } from 'react-i18next';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibGV5bGlhYmJhcyIsImEiOiJja3dxZHIxNW8wbHVqMnh1c3lxdGg1bGNjIn0.IZMkUg7j0pY4D8ozmZ8fNw',
  doubleClickZoom: false,
  touchZoomRotate: false,
  scrollZoom: false,
});

let flag = true;
type Error = {
  full_name?: string[];
  email?: string[];
  message?: string[];
};

const ContactsPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { state } = React.useContext(AppContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<Error>();
  const alert = useAlert();

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const handleSendMessage = () => {
    const requestData = {
      full_name: name,
      email: email,
      message: message,
    };

    sendMessage(requestData)
      .then((response) => {
        //todo: change translation
        alert.success('Your message was sent successfully.');
        setName('');
        setEmail('');
        setMessage('');
        setErrors(undefined);
      })
      .catch((e) => {
        setErrors(e.errors);
        alert.error(e.message);
      });
  };

  return (
    <>
      <Header />
      <Navbar />
      <div
        className='contacts-page relative'
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div className='google-map'>
          <Map
            // eslint-disable-next-line react/style-prop-object
            style='mapbox://styles/leyliabbas/ckh4qynl90kx91anmv6ulziwq'
            containerStyle={{
              height: '60vh',
              width: '100vw',
            }}
            center={[state.settings.location.value.longitude, state.settings.location.value.latitude]}
            zoom={[16]}
          >
            <Marker
              coordinates={[state.settings.location.value.longitude, state.settings.location.value.latitude]}
              anchor='bottom'
            >
              <div className='pin'></div>
            </Marker>
          </Map>
        </div>
        <Title
          label={t('contacts')}
          position='none'
          marginTop={width > 1224 ? 60 : 46}
        />
        <div className='container'>
          <div className='contacts-page-contacts'>
            <div className='contacts-page-contacts-list-item'>
              <div>
                <Image src='/assets/icons/contacts/call.svg' alt='icon' />
              </div>
              <span>
                {state.settings.footerPhone.value[0]} <br />{' '}
                {state.settings.footerPhone.value[1]}
              </span>
            </div>
            <div className='contacts-page-contacts-list-item'>
              <div>
                <Image
                  src='/assets/icons/contacts/paper-plane.svg'
                  alt='icon'
                />
              </div>
              <span>
                {state.settings.footerEmail.value[0]} <br />{' '}
                {state.settings.footerEmail.value[1]}
              </span>
            </div>
            <div className='contacts-page-contacts-list-item'>
              <div>
                <Image src='/assets/icons/contacts/pin.svg' alt='icon' />
              </div>
              <span>{state.settings.footerAddress.value}</span>
            </div>
          </div>
        </div>
        <Title
          label={t('write_to_us')}
          position='left'
          marginTop={width > 1224 ? 133 : 50}
        />
        <div className='container'>
          <Description label={t('message_description')} />
          <div className='form'>
            <Form onSubmit={handleSendMessage}>
              <Form.Input
                name='name'
                placeholder={t('name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={
                  errors && errors.full_name && errors.full_name.length !== 0
                    ? {
                        content: errors?.full_name && errors?.full_name[0],
                      }
                    : false
                }
              />
              <Form.Input
                name='email'
                placeholder={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  errors && errors.email && errors.email.length !== 0
                    ? {
                        content: errors?.email && errors?.email[0],
                      }
                    : false
                }
              />
              <Form.TextArea
                name='message'
                placeholder={t('message')}
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                error={
                  errors && errors.message && errors.message.length !== 0
                    ? {
                        content: errors?.message && errors?.message[0],
                      }
                    : false
                }
              />
              <Button
                type='submit'
                label={t('send_message')}
                position='right'
                marginTop={width > 1224 ? 69 : 44}
              />
            </Form>
          </div>
        </div>
        <Dots
          row={4}
          column={2}
          color='#F2F2F2'
          opacity={1}
          top={750}
          left={16}
        />
        <Dots
          row={4}
          column={5}
          color='#F2F2F2'
          opacity={1}
          bottom={-30}
          right={16}
        />
      </div>
      <Footer />
    </>
  );
};

export default ContactsPage;
