import { AxiosRequestConfig } from 'axios';

export const domain = process.env.APP_DOMAIN

/**
 * Common REST configuration.
 */
 export const commonRestConfig: AxiosRequestConfig = {
  baseURL: process.env.APP_DOMAIN,
  timeout: 1 * 60 * 1000,
};

/** Error class name. */
export const ERROR_CLASS_NAME = 'elementWithError';
