const GeneralType = {
    key: '',
    value: ''
}

const Location = {
    key: '',
    value: {
        latitude: 40.409264,
        longitude: 49.867092
    }
}

export const initialSettings = {
    facebook: GeneralType,
    twitter: GeneralType,
    linkedin: GeneralType,
    instagram: GeneralType,
    headerPhone: GeneralType,
    headerAddress: GeneralType,
    footerPhone: GeneralType,
    footerAddress: GeneralType,
    footerEmail: GeneralType,
    location: Location
}
