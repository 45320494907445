import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Image, Input, Sidebar } from 'semantic-ui-react';
import Dots from '../Dots';
import { search } from '../../modules/services';
import { SearchArticle } from './SearchData';
import { AppContext } from '../../modules/contexts';
import { useAnimation, motion } from 'framer-motion';
import './index.scss';

type Props = {
    searchSidebar: boolean;
    closeSearchSidebar: () => any;
}

const Search: React.FC<Props> = (props) => {
    const [data, setData] = useState({ articles: [], members: [], brands: [], partners: [] });
    const [visible, setVisible] = useState(true);
    const [flag, setFlag] = useState(false);
    const { dispatch } = useContext(AppContext);
    const controls = useAnimation();
    const [searchValue, setSearchValue] = useState('');

    const searchData = useCallback((data: string) => {
        setSearchValue(data);
        if (data.length !== 0) {
            search(data).then((response: any) => {
                if (response.articles || response.brands || response.members || response.partners) {
                    setVisible(false);
                    setFlag(true);
                } else {
                    setVisible(true);
                    setFlag(false);
                }
                setData(response);
            })
        } else {
            setFlag(false);
            setVisible(true);
            setData({ articles: [], members: [], brands: [], partners: [] })
        }
    }, []);

    const closeSearchSidebar = () => {
        setSearchValue('');
        setFlag(false);
        setData({ articles: [], members: [], brands: [], partners: [] });
        dispatch({ type: "CLOSE_SEARCH_SIDEBAR" });
    }

    useEffect(() => {
        if (flag) {
            controls.start({
                marginTop: [200, 0]
            });
        } else {
            controls.start({
                marginTop: [0, 200],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flag]);

    return (
        <Sidebar
            animation="overlay"
            direction="right"
            visible={props.searchSidebar}
            className="menu-sidebar"
        >
            <div className="container">
                <div className="menu-sidebar-content">
                    <div className="menu-sidebar-header">
                        <div />
                        <div className="header-sidebar-close" onClick={closeSearchSidebar}>
                            <Image
                                className="header-sidebar-close-icon"
                                style={{ width: '23px' }}
                                src='/assets/icons/cancel.svg'
                            // src='/assets/icons/sidebar-arrow-left.svg'
                            />
                            {/* <Image
                                className="header-sidebar-close-icon"
                                src='/assets/icons/cancel.svg'
                            /> */}
                        </div>
                    </div>
                    <motion.div
                        className="global-search"
                        initial={{ marginTop: 200 }}
                        animate={controls}
                        transition={{
                            duration: 0.7,
                            ease: "easeInOut",
                        }}
                    >
                        <div className="global-search-area">
                            <Image
                                className="global-search-icon"
                                src='/assets/icons/search-outline.svg'
                                alt="icon"
                            />
                            <Input
                                value={searchValue}
                                className="global-search-input"
                                onChange={(e) => searchData(e.target.value)}
                            />
                        </div>
                    </motion.div>
                    {flag && <div className="search-result">
                        <div className="search-result-box">
                            {data.articles.map((item, key) =>
                                <SearchArticle key={key} article={item} />
                            )}
                        </div>
                        {/* <div className="search-result-box">
                            {data.brands.map((item, key) =>
                                <SearchBrand key={key} brand={item} />
                            )}
                        </div> */}
                    </div>}
                </div>
            </div>
            {visible && <Dots row={4} column={7} color="#F2F2F2" opacity={1} bottom={20} left={0} />}
        </Sidebar >
    );
};

export default Search;
