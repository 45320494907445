import React from 'react';
import moment from 'moment';
import './index.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  vacancy: any;
};

const VacancyDetailsList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="careers-page-details">
      <div className="careers-page-details-box">
        <span className="careers-page-details-label">{t('recruiter')}</span>
        <span className="careers-page-details-info">
          {props.vacancy.recruiter}
        </span>
      </div>
      <div className="careers-page-details-box">
        <span className="careers-page-details-label">{t('location')}</span>
        <span className="careers-page-details-info">
          {props.vacancy.location}
        </span>
      </div>
      <div className="careers-page-details-box">
        <span className="careers-page-details-label">{t('posted')}</span>
        <span className="careers-page-details-info">
          {moment(props.vacancy.created_at).format('ll')}
        </span>
      </div>
      <div className="careers-page-details-box">
        <span className="careers-page-details-label">{t('deadline')}</span>
        <span className="careers-page-details-info">
          {moment(props.vacancy.deadline).format('ll')}
        </span>
      </div>
      <div className="careers-page-details-box">
        <span className="careers-page-details-label">{t('job_type')}</span>
        <span className="careers-page-details-info">
          {props.vacancy.translation?.job_type}
        </span>
      </div>
    </div>
  );
};

export default VacancyDetailsList;
