import React from 'react';
import { Sidebar, Image } from 'semantic-ui-react';
import { AppContext } from '../../modules/contexts';
import './index.scss';

const SidebarDescription: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);

  const closeModal = () => {
    if (state.teamSidebar) {
      dispatch({ type: 'CLOSE_TEAM_SIDEBAR' });
    }
    if (state.brandSidebar) {
      dispatch({ type: 'CLOSE_BRAND_SIDEBAR' });
    }
    if (state.partnerSidebar) {
      dispatch({ type: 'CLOSE_PARTNER_SIDEBAR' });
    }
  };
  return (
    <Sidebar
      animation="overlay"
      direction="right"
      visible={state.teamSidebar || state.brandSidebar || state.partnerSidebar}
      className="info-sidebar"
    >
      <div className="back-icon" onClick={closeModal}>
        <Image
          className={
            state.teamSidebar || state.brandSidebar || state.partnerSidebar
              ? 'transform-left'
              : 'transform-right'
          }
          src="/assets/icons/teams/arrow-left.svg"
          alt="icon"
        />
      </div>
      {state.siderbarContent}
    </Sidebar>
  );
};

export default SidebarDescription;
