import React, { useEffect, useState, useCallback } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import ImageCarousel from '../../components/ImageCarousel';
import Partners from '../../components/Partner';
import { getPartners } from '../../modules/services';
import { Sidebar } from 'semantic-ui-react';
import { AppContext } from '../../modules/contexts';
import SidebarDescription from '../../components/Sidebar';
import Dots from '../../components/Dots';
import Loader from '../../components/Layout/Loader';
// @ts-ignore
import { Fade } from 'react-reveal';
import './index.scss';

let flag = true;

const PartnesPage: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const [partners, setPartners] = useState({
    content: { title: '', text: '' },
    slides: [],
    partners: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const closeSidebar = () => {
    if (state.partnerSidebar) {
      dispatch({ type: 'CLOSE_PARTNER_SIDEBAR' });
    }
  };

  const getPartnersData = useCallback(() => {
    getPartners().then((response: any) => {
      setPartners(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getPartnersData();
  }, [getPartnersData]);

  return (
    <>
      <Sidebar.Pushable>
        <Sidebar.Pusher dimmed={state.partnerSidebar} onClick={closeSidebar}>
          <Header />
          <Navbar />
          <Loader loading={loading}>
            <ImageCarousel images={partners.slides} />
            <div className="partners-page relative">
              <div className="container">
                <Fade bottom>
                  <Title label={partners.content.title} position="none" />
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: partners.content.text }}
                  />
                </Fade>
                <Fade bottom>
                  <Partners type="general" partners={partners.partners} />
                </Fade>
              </div>
              <Dots
                row={4}
                column={5}
                color="#A6C4E5"
                opacity={1}
                top={128}
                left={27}
              />
              <Dots
                row={3}
                column={5}
                color="#A6C4E5"
                opacity={1}
                bottom={-53}
                right={37}
              />
            </div>
            <Footer />
          </Loader>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <SidebarDescription />
    </>
  );
};

export default PartnesPage;
