import React, { useState, useCallback, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../hooks/dimension';
import Vacancies from '../../components/Careers';
import { getCareer } from '../../modules/services';
import Dots from '../../components/Dots';
import Loader from '../../components/Layout/Loader';
import './index.scss';
import { useTranslation } from 'react-i18next';

let flag = true;

const CareersPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [career, setCareer] = useState({
    content: { text: '' },
    positions: { data: [] },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const getCareerData = useCallback(() => {
    getCareer().then((response: any) => {
      setCareer(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getCareerData();
  }, [getCareerData]);

  return (
    <>
      <Header />
      <Navbar />
      <Loader loading={loading}>
        <div className="careers-page relative">
          <Title label={t('careers')} position="left" />
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: career.content.text }}
          />
          <div className="container">
            <Vacancies vacancies={career.positions.data} />
          </div>
          <Button
            label={t('more_results')}
            marginTop={width > 1224 ? 124 : 90}
            position="bottom"
          />
          <Dots
            row={4}
            column={5}
            color="#F2F2F2"
            opacity={1}
            top={-40}
            right={15}
          />
        </div>
        <Footer />
      </Loader>
    </>
  );
};

export default CareersPage;
