import React, { useCallback, useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { getArticle } from '../../modules/services';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import ImageCarousel from '../../components/ImageCarousel';
import { Image } from 'semantic-ui-react';
import { history } from '../../utils/history';
import Description from '../../components/Description';
import { FacebookShareButton } from 'react-share';
import { domain } from '../../utils/constants';
import Loader from '../../components/Layout/Loader';
// @ts-ignore
import { Fade } from 'react-reveal';
import './index.scss';
import { useTranslation } from 'react-i18next';

type TParams = { id: string };

let flag = true;

const ArticlePage: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const { t } = useTranslation();
  const [article, setArticle] = useState({
    data: { gallery: [], translation: { title: '', text: '', subtitle: '' } },
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  let id = match.params.id;

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const getArticleData = useCallback(() => {
    getArticle(id).then((response: any) => {
      setArticle(response);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    getArticleData();
  }, [getArticleData]);

  return (
    <>
      <Header />
      <Navbar />
      <Loader loading={loading}>
        <ImageCarousel images={article.data.gallery} />
        <div className="article-page relative">
          <div className="container">
            <Fade bottom>
              <Title label={article.data.translation?.title} position="none" />
              <Description label={article.data.translation?.subtitle} />
            </Fade>
            <Fade bottom>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: article.data.translation?.text,
                }}
              />
            </Fade>
            <Fade bottom>
              <div className="article-page-buttons">
                <Button
                  label={t('see_all_news')}
                  position="left"
                  onClick={() => history.push('/news')}
                />
                <FacebookShareButton
                  url={`${domain}${location.pathname}`}
                  className="sosial-share"
                >
                  <span>{t('share')}</span>
                  <div className="share">
                    <Image src="/assets/icons/share.svg" alt="icon" />
                  </div>
                </FacebookShareButton>
              </div>
            </Fade>
          </div>
        </div>
        <Footer />
      </Loader>
    </>
  );
};

export default ArticlePage;
