import React, { useState, useCallback, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../hooks/dimension';
import Description from '../../components/Description';
import Accordion from '../../components/Careers/Accordion';
import { getVacancy } from '../../modules/services';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import Vacancies from '../../components/Careers';
import { history } from '../../utils/history';
import Dots from '../../components/Dots';
import VacancyDetailsList from '../../components/Careers/VacancyDetailsList';
import { domain } from '../../utils/constants';
import { FacebookShareButton } from "react-share";
import './index.scss';
import Loader from '../../components/Layout/Loader';

let flag = true;

type TParams = { id: string };

const VacancyPage: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
    const { width } = useWindowDimensions();
    const location = useLocation();
    const [expanded, setExpanded] = useState<false | number>(0);
    const [loading, setLoading] = useState(true);
    let id = match.params.id;

    const [vacancy, setVacancy] = useState(
        {
            position: {
                components: [],
                translation: { title: '', department: '', job_type: '' },
                recruiter: '',
                location: '',
                created_at: '',
                deadline: '',
            },
            related_positions: []
        })

    const getVacancyData = useCallback(() => {
        getVacancy(id).then((response: any) => {
            setVacancy(response);
            window.scrollTo(0, 0);
            setLoading(false);
        })
    }, [id]);

    useEffect(() => {
        flag && window.scrollTo(0, 0);
        flag = false;
    });

    useEffect(() => {
        getVacancyData();
    }, [getVacancyData]);

    const goToCareerPage = () => {
        history.push('/careers');
    }

    const goToApplyPage = () => {
        history.push(`/apply/${id}`);
    }
    return (
        <>
            <Header />
            <Navbar />
            <Loader loading={loading}>
                <div className="careers-page relative">
                    <Title label={vacancy.position.translation?.title} position="none" />
                    <Description label={vacancy.position.translation?.department} />
                    <div className="container">
                        <VacancyDetailsList vacancy={vacancy.position} />
                        <div className="careers-page-accordion">
                            {vacancy.position.components.map((item, key) => (
                                <Accordion key={key} i={key} data={item} expanded={expanded} setExpanded={setExpanded} />
                            ))}
                        </div>
                    </div>
                    <div className="careers-page-buttons">
                        <Button
                            label="Apply"
                            marginTop={width > 1224 ? 124 : 90}
                            position="bottom"
                            onClick={goToApplyPage}
                        />
                        <FacebookShareButton
                            url={`${domain}${location.pathname}`}
                            className="sosial-share"
                            style={{ marginTop: 80 }}
                        >
                            <span>Share</span>
                            <div className="share">
                                <Image src="/assets/icons/share.svg" alt="icon" />
                            </div>
                        </FacebookShareButton>
                    </div>
                    <Title label="Related vacancies" position="left" />
                    <div className="container">
                        <Vacancies vacancies={vacancy.related_positions} />
                    </div>
                    <Button
                        label="More results"
                        position="right"
                        marginTop={width > 1224 ? 84 : 64}
                        onClick={goToCareerPage}
                    />
                    <Dots row={4} column={7} color="#D6D6D6" opacity={1} bottom={850} left={0} />
                </div>
                <Footer />
            </Loader>
        </>
    );
};

export default VacancyPage;


