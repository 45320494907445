import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { translations as TRANSLATIONS_AZ } from '../locales/az.json';
import { translations as TRANSLATIONS_EN } from '../locales/en.json';
import { translations as TRANSLATIONS_RU } from '../locales/ru.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      az: {
        translation: TRANSLATIONS_AZ,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
      ru: {
        translation: TRANSLATIONS_RU,
      }
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: false,
    keySeparator: false,
  });

i18n.changeLanguage(localStorage.getItem('lang') || 'az');
export default i18n;
