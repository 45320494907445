import React from 'react';
import Carousel from 'nuka-carousel';
import { Button, Image } from 'semantic-ui-react';
import { ISlides } from '../../modules/models';
import { useWindowInnerDimensions } from '../../hooks/dimension';
import './index.scss';

type Props = {
  images: ISlides[];
};

const Gallery: React.FC<Props> = ({ images }) => {
  const { width } = useWindowInnerDimensions();

  return (
    <Carousel
      wrapAround
      slidesToShow={width > 428 ? 5 : 1}
      cellAlign='center'
      className='gallery'
      speed={150}
      dragging
      renderCenterLeftControls={({ previousSlide }) =>
        width > 428 ? (
          <Button className='carousel-button' onClick={previousSlide}>
            <Image src='/assets/icons/arrow-left-blue.svg' alt='icon' />
          </Button>
        ) : null
      }
      renderCenterRightControls={({ nextSlide }) =>
        width > 428 ? (
          <Button className='carousel-button' onClick={nextSlide}>
            <Image src='/assets/icons/arrow-right-blue.svg' alt='icon' />
          </Button>
        ) : null
      }
      renderBottomCenterControls={null}
      defaultControlsConfig={{
        pagingDotsStyle: {
          fill: 'white',
        },
      }}
    >
      {images?.map((image, key) => (
        <img key={key} alt={`gallery-${key}`} src={image.photo_url} />
      ))}
    </Carousel>
  );
};

export default Gallery;
