import React, { useState, useRef, useEffect, useCallback } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../hooks/dimension';
import Description from '../../components/Description';
import { Image, Form } from 'semantic-ui-react';
import { history } from '../../utils/history';
import Dots from '../../components/Dots';
import { apply, getVacancy } from '../../modules/services';
import { useAlert } from 'react-alert';
import { RouteComponentProps } from 'react-router-dom';
import './index.scss';
import Loader from '../../components/Layout/Loader';
import { useTranslation } from 'react-i18next';

let flag = true;

type TParams = { id: string };

type Error = {
  firstName?: string[];
  lastName?: string[];
  email?: string[];
  phone?: string[];
};

const ApplyPage: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [file, setFile] = useState('');
  const [errors, setErrors] = useState<Error>();
  const [vacancy, setVacancy] = useState({
    position: {
      components: [],
      translation: { title: '', department: '', job_type: '' },
      recruiter: '',
      location: '',
      created_at: '',
      deadline: '',
    },
    related_positions: [],
  });
  const [loading, setLoading] = useState(true);
  const alert = useAlert();
  let id = match.params.id;

  const ref = useRef<HTMLInputElement>(null);

  const getVacancyData = useCallback(() => {
    getVacancy(id).then((response: any) => {
      setVacancy(response);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  useEffect(() => {
    getVacancyData();
  }, [getVacancyData]);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const applyVacancy = () => {
    let formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('document', file);

    apply(id, formData)
      .then((response) => {
        setErrors(undefined);
        goToSuccessPage();
      })
      .catch((e) => {
        setErrors(e.errors);
        alert.error(e.message);
      });
  };

  const goToSuccessPage = () => {
    history.push('/success');
  };

  return (
    <>
      <Header />
      <Navbar />
      <Loader loading={loading}>
        <div className="careers-page relative">
          <Title label={vacancy.position.translation?.title} position="none" />
          <Description label={vacancy.position.translation?.department} />
          <Title
            marginTop={width > 1224 ? 131 : 82}
            label={t('application_form')}
            position="right"
          />
          <div className="container">
            <div className="form">
              <Form onSubmit={applyVacancy}>
                <Form.Input
                  name="firstName"
                  placeholder={t('first_name')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={
                    errors && errors.firstName && errors.firstName.length !== 0
                      ? {
                          content: errors?.firstName && errors?.firstName[0],
                        }
                      : false
                  }
                />
                <Form.Input
                  name="lastName"
                  placeholder={t('last_name')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={
                    errors && errors.lastName && errors.lastName.length !== 0
                      ? {
                          content: errors?.lastName && errors?.lastName[0],
                        }
                      : false
                  }
                />
                <Form.Input
                  name="email"
                  placeholder={t('email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    errors && errors.email && errors.email.length !== 0
                      ? {
                          content: errors?.email && errors?.email[0],
                        }
                      : false
                  }
                />
                <Form.Input
                  name="phone"
                  placeholder={t('phone')}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={
                    errors && errors.phone && errors.phone.length !== 0
                      ? {
                          content: errors?.phone && errors?.phone[0],
                        }
                      : false
                  }
                />
                <input
                  ref={ref}
                  hidden
                  type="file"
                  onChange={(e) => setFile(e.target.value)}
                />
                <div className="form-file-upload">
                  <span className="file-upload-path">{file}</span>
                  <div
                    className="file-upload-button"
                    onClick={() => ref.current?.click()}
                  >
                    <Image src="/assets/icons/upload.svg" alt="icon" />
                  </div>
                  <span className="file-upload-label">
                    {t('drag_drop_file_here')}
                  </span>
                  <span className="file-upload-info">
                    {t('max_file_size_rule')}
                  </span>
                </div>
                <Button
                  type="submit"
                  label={t('submit')}
                  position="right"
                  marginTop={width > 1224 ? 69 : 44}
                />
              </Form>
            </div>
          </div>
          <Dots
            row={4}
            column={3}
            color="#A6C4E5"
            opacity={1}
            top={-20}
            right={13}
          />
          <Dots
            row={4}
            column={7}
            color="#D6D6D6"
            opacity={1}
            bottom={-30}
            left={0}
          />
        </div>
        <Footer />
      </Loader>
    </>
  );
};

export default ApplyPage;
