import React, { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import { useWindowDimensions } from '../../hooks/dimension';
import { history } from '../../utils/history';
import Dots from '../../components/Dots';
import './index.scss';
import Title from '../../components/Title';
import Description from '../../components/Description';
import { Image } from 'semantic-ui-react';
import Button from '../../components/Button';

let flag = true;

const SuccessPage: React.FC<any> = (props) => {
    const { width } = useWindowDimensions();

    useEffect(() => {
        flag && window.scrollTo(0, 0);
        flag = false;
    });

    const okay = () => {
        history.push('/careers');
    }

    return (
        <>
            <Header />
            <Navbar />
            <div className="careers-page relative">
                <div className="container">
                    <div className="success-image">
                        <Image src="/assets/images/success.svg" alt="success"  />
                    </div>
                    <Title label="Congratulations" position="none" />
                    <div style={{ maxWidth: 507, margin: "20px auto" }}>
                        <Description label="Thank you for your letter. We will contact you as soon as possible." />
                    </div>
                    <div className="square-blue" />
                    <Button
                        label="Okay"
                        position="left"
                        marginTop={width > 1224 ? 73 : 0}
                        onClick={okay}
                    />
                </div>
                <Dots row={4} column={4} color="#A6C4E5" opacity={1} top={-20} right={32} />
                <Dots row={4} column={7} color="#D6D6D6" opacity={1} bottom={-30} left={0} />
            </div>
            <Footer />
        </>
    );
};

export default SuccessPage;


