import React from 'react';
import { Menu } from 'semantic-ui-react';
import Brand from './Brand';
import { useAnimation } from 'framer-motion';
import { IBrands } from '../../modules/models';
import './index.scss';

type Props = {
  brands: IBrands[];
  getData: (id: number) => any;
};

const Brands: React.FC<Props> = (props) => {
  const [active, setActive] = React.useState(1);
  const controls = useAnimation();

  const hanleBrandClick = (id: number) => {
    setActive(id);
    animate();
    props.getData(id - 1);
  };

  const animate = () => {
    controls.start({ opacity: 1 });
    setTimeout(() => {
      controls.set({ opacity: 1 });
    }, 100);
  };

  return (
    <div>
      <div className="brands-names">
        <Menu text>
          <Menu.Item
            name="all"
            active={active === 1}
            onClick={() => hanleBrandClick(1)}
          />
          <Menu.Item
            name="food"
            active={active === 2}
            onClick={() => hanleBrandClick(2)}
          />
          <Menu.Item
            name="nonfood"
            active={active === 3}
            onClick={() => hanleBrandClick(3)}
          />
        </Menu>
      </div>
      <div className="brands">
        {props.brands.map((brand, key) => (
          <Brand
            key={key}
            logo={brand.logo_url}
            cover={brand.cover_url}
            description={brand.translation.about}
            url={brand.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Brands;
