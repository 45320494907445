import React from 'react';
import styled from '@emotion/styled';
import Spinner from 'react-spinkit';

const LoaderBox = styled.div`
  height: 50vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  loading: boolean;
};

const Loader: React.FC<Props> = (props) => {
  return (
    <div>
      {!props.loading ? (
        props.children
      ) : (
        <LoaderBox>
          <Spinner
            fadeIn="none"
            name="cube-grid"
            color="#3373B9"
            style={{ width: 65, height: 65 }}
          />
        </LoaderBox>
      )}
    </div>
  );
};

export default Loader;
