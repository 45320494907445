export const teamSidebarReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'OPEN_TEAM_SIDEBAR':
            return true;
        case 'CLOSE_TEAM_SIDEBAR':
            return false;
        default: return state;
    }
}

export const brandSidebarReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'OPEN_BRAND_SIDEBAR':
            return true;
        case 'CLOSE_BRAND_SIDEBAR':
            return false;
        default: return state;
    }
}

export const partnerSidebarReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'OPEN_PARTNER_SIDEBAR':
            return true;
        case 'CLOSE_PARTNER_SIDEBAR':
            return false;
        default: return state;
    }
}

export const sidebarContentReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'ADD_SIDEBAR_CONTENT':
            return action.payload;
        default: return state;
    }
}

export const menuSidebarReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'OPEN_MENU_SIDEBAR':
            return true;
        case 'CLOSE_MENU_SIDEBAR':
            return false;
        default: return state;
    }
}

export const searchSidebarReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'OPEN_SEARCH_SIDEBAR':
            return true;
        case 'CLOSE_SEARCH_SIDEBAR':
            return false;
        default: return state;
    }
}

