import { BASE_URL } from '../constants';
import { getRequest, postRequest } from '../../utils/rest';

const lang = () => {
  return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
};

const header = {
  headers: {
    'Accept-Language': lang(),
    'Content-Type': 'application/json',
  },
};

export const getSettingsData = () => {
  return getRequest(`${BASE_URL}/api/settings`, header);
};

export const sendMessage = (data: any) => {
  return postRequest(`${BASE_URL}/api/contact`, data, header);
};

export const getPartners = () => {
  return getRequest(`${BASE_URL}/api/partners`, header);
};

export const getBrands = (id: number) => {
  return getRequest(
    id
      ? `${BASE_URL}/api/brands?category_id=${id}`
      : `${BASE_URL}/api/brands`,
    header
  );
};

export const getHome = () => {
  return getRequest(`${BASE_URL}/api/home`, header);
};

export const getUnilever = () => {
  return getRequest(`${BASE_URL}/api/unilever`, header);
};

export const getNews = () => {
  return getRequest(`${BASE_URL}/api/articles`, header);
};

export const getArticle = (id: string) => {
  return getRequest(`${BASE_URL}/api/articles/${id}`, header);
};

export const getCareer = () => {
  return getRequest(`${BASE_URL}/api/careers`, header);
};

export const getVacancy = (id: string) => {
  return getRequest(`${BASE_URL}/api/careers/${id}`, header);
};

export const apply = (id: string, data: any) => {
  return postRequest(`${BASE_URL}/api/careers/${id}`, data, header);
};

export const getAbout = () => {
  return getRequest(`${BASE_URL}/api/about`, header);
};

export const search = (data: string) => {
  return getRequest(`${BASE_URL}/api/search?q=${data}`, header);
};

export const getTeam = (id: number) => {
  return getRequest(`${BASE_URL}/api/team?team_id=${id}`, header);
};

export const subscribe = (data: any) => {
  return postRequest(`${BASE_URL}/api/subscribe`, data, header);
};
