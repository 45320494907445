import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { commonRestConfig } from './constants';
import { errorWrapper } from './error';

/**
 * HTTP request method.
 */
type TRequestMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

/**
 * Executing HTTP request.
 *
 * @param {TRequestMethod} method HTTP request method.
 * @param {string} url Request URL.
 * @param {TRequestData} data Data for POST/PUT requests.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
function requestAPI<TRequestData, TResponseData>(
    method: TRequestMethod,
    url: string,
    data: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    const request: AxiosRequestConfig = {
        ...commonRestConfig,
        ...config,
        data,
        method,
        url,
    };

    return new Promise<TResponseData>((resolve, reject) => {
        axios
            .request(request)
            .then((response: AxiosResponse<TResponseData>) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                const wrappedError = errorWrapper(error);
                reject(wrappedError);
            });
    });
}

/**
 * GET request method.
 *
 * @param {string} url REST path.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function getRequest<TResponseData>(
    url: string,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('get', url, {}, config);
}

/**
 * POST request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for POST request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function postRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('post', url, data, config);
}

/**
 * PUT request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for PUT request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function putRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('put', url, data, config);
}

/**
 * PATCH request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for PUT request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function patchRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('patch', url, data, config);
}

/**
 * DELETE request method.
 *
 * @param {string} url REST path.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function delRequest<TResponseData>(
    url: string,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('delete', url, null, config);
}
