import React, { useEffect, useState } from 'react';
import { AppContext } from './modules/contexts';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import HomePage from './pages/Home';
import { history } from './utils/history';
import NewsPage from './pages/News';
import CareersPage from './pages/Careers';
import ContactsPage from './pages/Contacts';
import PartnersPage from './pages/Partners';
import BrandsPage from './pages/Brands';
import AboutPage from './pages/About';
import VacancyPage from './pages/Careers/Vacancy';
import { getSettingsData } from './modules/services';
import ArticlePage from './pages/Article';
import ApplyPage from './pages/Careers/Apply';
import SuccessPage from './pages/Careers/Success';
import styled from '@emotion/styled';
import Spinner from 'react-spinkit';
import { Image } from 'semantic-ui-react';
// @ts-ignore
import { animateScroll as scroll } from 'react-scroll';
import './App.scss';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './utils/i18n';

const Loader = styled.div`
  height: 100vh;
  background-color: #3373b9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let mounted = true;

function App() {
  const { state, dispatch } = React.useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [bar, setBar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'az');
    }
    mounted &&
      getSettingsData().then((response) => {
        dispatch({
          type: 'UPDATE_SETTINGS',
          payload: response,
        });
        mounted = false;
        setLoading(false);
      });

    window.addEventListener('scroll', hideBar);
    return () => {
      window.removeEventListener('scroll', hideBar);
    };
  });

  const hideBar = () => {
    window.scrollY > 400 ? setBarTrue() : setBarFalse();
  };

  const setBarTrue = () => {
    if (!bar) {
      setBar(true);
    }
  };

  const setBarFalse = () => {
    // if(bar) {
    setBar(false);
    // }
  };

  return (
    <I18nextProvider i18n={i18n}>
      {loading ? (
        <Loader>
          <Spinner
            fadeIn="none"
            name="cube-grid"
            color="white"
            style={{ width: 65, height: 65 }}
          />
        </Loader>
      ) : (
        <div
          className={
            state.menu || state.searchSidebar
              ? 'no-scroll-y no-scroll-x'
              : 'no-scroll-x'
          }
        >
          <Router history={history}>
            <Switch>
              <Route path="/" component={HomePage} exact />
              <Route path="/about" component={AboutPage} />
              <Route path="/brands" component={BrandsPage} />
              <Route path="/partners" component={PartnersPage} exact />
              <Route path="/careers" component={CareersPage} />
              <Route path="/vacancy/:id" component={VacancyPage} />
              <Route path="/apply/:id" component={ApplyPage} />
              <Route path="/success" component={SuccessPage} />
              <Route path="/news" component={NewsPage} />
              <Route path="/article/:id" component={ArticlePage} />
              <Route path="/contacts" component={ContactsPage} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
          {bar && (
            <div className="back-to-top" onClick={() => scroll.scrollToTop()}>
              <span>{t('back_to_top')}</span>
              <Image
                className="back-to-top-icon"
                alt="icon"
                src="/assets/icons/back-to-top.svg"
              />
            </div>
          )}
        </div>
      )}
    </I18nextProvider>
  );
}

export default App;
