import React, { useState, useContext } from 'react';
import { Image, Input, Menu } from 'semantic-ui-react';
import { history } from '../../utils/history';
import { AppContext } from '../../modules/contexts';
import { subscribe } from '../../modules/services';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import './index.scss';

const nav = [
  { name: 'aboutUs', url: '/about' },
  { name: 'brands', url: '/brands' },
  { name: 'partners', url: '/partners' },
  { name: 'careers', url: '/careers' },
  { name: 'news', url: '/news' },
  { name: 'contacts', url: '/contacts' },
];

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState('');
  const [email, setEmail] = useState('');
  const { state } = useContext(AppContext);
  const alert = useAlert();

  const handleClick = (item: { name: string; url: string }) => {
    history.push(item.url);
    setActive(item.name);
  };

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      let data = {
        email: e.target.value,
      };
      subscribe(data)
        .then((response) => {
          setEmail('');
          alert.success('You have successfully subscribed to the newsletter.');
        })
        .catch((e) => {
          alert.error(e.message);
        });
    }
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-sections">
          <div className="footer-section-one">
            <Menu text className="footer-menu">
              {nav.map((item, key) => (
                <Menu.Item
                  key={key}
                  className="footer-menu-list-item hover"
                  name={item.name}
                  content={t(item.name)}
                  active={active === item.name}
                  onClick={() => handleClick(item)}
                />
              ))}
            </Menu>
            <div className="footer-contacts">
              <div className="footer-contacts-list-item">
                <div className="footer-contacts-icon">
                  <Image src="/assets/icons/footer/call.svg" alt="icon" />
                </div>
                <span className="footer-contacts-text">
                  {state.settings.footerPhone.value[0]} <br />{' '}
                  {state.settings.footerPhone.value[1]}
                </span>
              </div>
              <div className="break-xs"></div>
              <div className="footer-contacts-list-item">
                <div className="footer-contacts-icon">
                  <Image src="/assets/icons/footer/pin.svg" alt="icon" />
                </div>
                <span className="footer-contacts-text">
                  {state.settings.footerAddress.value}
                </span>
              </div>
              <div className="break-sm"></div>
              <div className="footer-contacts-list-item last">
                <div className="footer-contacts-icon">
                  <Image
                    src="/assets/icons/footer/paper-plane.svg"
                    alt="icon"
                  />
                </div>
                <span className="footer-contacts-text">
                  {state.settings.footerEmail.value[0]} <br />{' '}
                  {state.settings.footerEmail.value[1]}
                </span>
              </div>
            </div>
          </div>
          <div className="footer-section-two">
            <div className="footer-follow">
              <div className="footer-follow-list-item">
                <span className="footer-follow-text">{t('follow_us')}</span>
              </div>
              <div className="break-md"></div>
              <a
                href={state.settings.facebook.value}
                target="blank"
                className="footer-follow-list-item footer-follow-list-item-icon hover"
              >
                <Image
                  className="footer-follow-icon"
                  alt="icon"
                  src="/assets/icons/facebook.svg"
                />
              </a>
              <a
                href={state.settings.twitter.value}
                target="blank"
                className="footer-follow-list-item footer-follow-list-item-icon hover"
              >
                <Image
                  className="footer-follow-icon"
                  alt="icon"
                  src="/assets/icons/twitter.svg"
                />
              </a>
              <a
                href={state.settings.linkedin.value}
                target="blank"
                className="footer-follow-list-item footer-follow-list-item-icon hover"
              >
                <Image
                  className="footer-follow-icon"
                  alt="icon"
                  src="/assets/icons/linkedin.svg"
                />
              </a>
              <a
                href={state.settings.instagram.value}
                target="blank"
                className="footer-follow-list-item footer-follow-list-item-icon hover"
              >
                <Image
                  className="footer-follow-icon"
                  alt="icon"
                  src="/assets/icons/instagram.svg"
                />
              </a>
            </div>
            <div className="footer-subscribe">
              <div className="footer-subscribe-list-item">
                <span className="footer-subscribe-text">{t('subscribe')}</span>
              </div>
              <div className="footer-subscribe-list-item">
                <Input
                  className="footer-subscribe-input"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={keyPress}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-rights">
          <div className="footer-rights-list-item">
            <span className="footer-rights-text">
              © {new Date().getFullYear()} {t('all_rights_reserved')}
            </span>
          </div>
          <div className="footer-rights-list-item">
            <span className="footer-rights-text">{t('created_by')}</span>
            <Image
              className="footer-rights-scout"
              src="/assets/images/scout.svg"
              alt="scout"
              as="a"
              href="https://scout.llc/"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
