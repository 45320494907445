import React from 'react';
import './index.scss';

type Props = {
  label: string;
};

const Description: React.FC<Props> = (props) => {
  return <h3 className="description">{props.label}</h3>;
};

export default Description;
