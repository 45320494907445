import React from 'react';
import './index.scss';
import { Image } from 'semantic-ui-react';

type Props = {
  label: string;
  marginTop?: number;
  position: string;
  onClick?: () => any;
  type?: 'submit' | 'button' | 'reset';
};

const Button: React.FC<Props> = (props) => {
  return (
    <button
      className="general-button"
      style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
      onClick={props.onClick}
      type={props.type}
    >
      {props.label}
      {props.position === 'right' ? (
        <Image
          className="general-button-icon"
          alt="icon"
          src="/assets/icons/general-button.svg"
        />
      ) : props.position === 'left' ? (
        <Image
          className="general-button-icon"
          alt="icon"
          src="/assets/icons/general-button-left.svg"
        />
      ) : (
        <Image
          className="general-button-icon"
          alt="icon"
          src="/assets/icons/general-button-bottom.svg"
        />
      )}
    </button>
  );
};

export default Button;
