import React, { useContext, useState } from 'react';
import { Image, Menu, Sidebar } from 'semantic-ui-react';
import { AppContext } from '../../modules/contexts';
import { history } from '../../utils/history';
import Search from './Search';
import { motion, useAnimation } from 'framer-motion';
import './index.scss';
import { useTranslation } from 'react-i18next';

const languageOptions = [
  { text: 'Azerbaijani', value: 'az' },
  { text: 'English', value: 'en' },
  { text: 'Russian', value: 'ru' },
];

const nav = [
  { name: 'aboutUs', url: '/about' },
  { name: 'brands', url: '/brands' },
  { name: 'partners', url: '/partners' },
  { name: 'careers', url: '/careers' },
  { name: 'news', url: '/news' },
  { name: 'contacts', url: '/contacts' },
];

const Header: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState('');
  const [language, setLanguage] = useState(
    localStorage.getItem('lang') ? localStorage.getItem('lang') : 'az'
  );
  const controls = useAnimation();
  const controlsLanguage = useAnimation();
  const controlsLanguageOptions = useAnimation();
  const controlsLanguageSidebar = useAnimation();
  const controlsLanguageOptionsSidebar = useAnimation();

  const handleClick = (item: { name: string; url: string }) => {
    setActive(item.name);
    closeSidebar();
    history.push(item.url);
  };

  const openSidebar = () => {
    dispatch({ type: 'OPEN_MENU_SIDEBAR' });
    controls.start({
      marginBottom: [70, 0],
      opacity: [0, 1],
    });
  };

  const closeSidebar = () => {
    dispatch({ type: 'CLOSE_MENU_SIDEBAR' });
    controls.start({
      marginBottom: [0, 70],
      opacity: [1, 0],
    });
  };

  const changeLanguage = () => {
    controlsLanguage.start({
      opacity: [1, 0],
      display: 'none',
    });
    controlsLanguageOptions.start({
      opacity: [0, 1],
      display: 'flex',
    });
    controlsLanguageOptionsSidebar.start({
      display: 'flex',
    });
  };

  const chooseLanguage = (value: string) => {
    if (value !== localStorage.getItem('lang')) {
      setLanguage(value);
      i18n.changeLanguage(value);
      localStorage.setItem('lang', value);
      history.go(0);
    }
    controlsLanguage.start({
      opacity: [0, 1],
      display: 'flex',
    });
    controlsLanguageOptions.start({
      opacity: [1, 0],
      display: 'none',
    });
    controlsLanguageOptionsSidebar.start({
      display: 'none',
    });
  };

  const closeLanguage = () => {
    controlsLanguage.start({
      opacity: [0, 1],
      display: 'flex',
    });
    controlsLanguageOptions.start({
      opacity: [1, 0],
      display: 'none',
    });
    controlsLanguageOptionsSidebar.start({
      display: 'none',
    });
  };

  const openSearchSidebar = () => {
    dispatch({ type: 'OPEN_SEARCH_SIDEBAR' });
  };

  const closeSearchSidebar = () => {
    dispatch({ type: 'CLOSE_SEARCH_SIDEBAR' });
  };

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="header-sections">
            <div className="header-section-left">
              <div className="header-sosials">
                <a
                  href={state.settings.facebook.value}
                  target="blank"
                  className="header-sosials-list-item hover"
                >
                  <Image
                    className="header-sosials-item-icon"
                    src="/assets/icons/facebook.svg"
                    alt="icon"
                  />
                </a>
                <a
                  href={state.settings.twitter.value}
                  target="blank"
                  className="header-sosials-list-item hover"
                >
                  <Image
                    className="header-sosials-item-icon"
                    src="/assets/icons/twitter.svg"
                    alt="icon"
                  />
                </a>
                <a
                  href={state.settings.linkedin.value}
                  target="blank"
                  className="header-sosials-list-item hover"
                >
                  <Image
                    className="header-sosials-item-icon"
                    src="/assets/icons/linkedin.svg"
                    alt="icon"
                  />
                </a>
                <a
                  href={state.settings.instagram.value}
                  target="blank"
                  className="header-sosials-list-item hover"
                >
                  <Image
                    className="header-sosials-item-icon"
                    src="/assets/icons/instagram.svg"
                    alt="icon"
                  />
                </a>
              </div>
              <div className="header-contacts">
                <a
                  className="header-contacts-list-item"
                  href={`tel:${state.settings.headerPhone.value}`}
                >
                  <Image
                    className="header-contacts-item-icon"
                    src="/assets/icons/call.svg"
                    alt="icon"
                  />
                  <span className="header-contacts-text">
                    {state.settings.headerPhone.value}
                  </span>
                </a>
                <a
                  className="header-contacts-list-item"
                  href="https://goo.gl/maps/zYin1wnGAzCGm4826"
                  target="blank"
                >
                  <Image
                    className="header-contacts-item-icon"
                    src="/assets/icons/pin.svg"
                    alt="icon"
                  />
                  <span className="header-contacts-text">
                    {state.settings.headerAddress.value}
                  </span>
                </a>
              </div>
            </div>
            <div className="header-section-right">
              <motion.a
                className="header-languages"
                animate={controlsLanguage}
                transition={{
                  duration: 0.5,
                  ease: 'easeInOut',
                }}
                onClick={changeLanguage}
              >
                <Image
                  className="header-languages-icon transform"
                  src="/assets/icons/arrow-right.svg"
                  alt="icon"
                />
                {languageOptions
                  .filter((data) => data.value === language)
                  .map((item, key) => (
                    <span key={key} className="header-languages-text">
                      {item.text}
                    </span>
                  ))}
              </motion.a>
              <motion.div
                className="header-languages-options"
                initial={{ opacity: 0, display: 'none' }}
                animate={controlsLanguageOptions}
                transition={{
                  duration: 0.5,
                  ease: 'easeInOut',
                }}
              >
                <Image
                  className="header-languages-icon"
                  src="/assets/icons/arrow-right.svg"
                  alt="icon"
                  onClick={closeLanguage}
                />
                {languageOptions.map((lang, key) => (
                  <div
                    key={key}
                    className="header-languages-text"
                    style={
                      lang.value === language ? { color: '#0B296C' } : undefined
                    }
                    onClick={() => chooseLanguage(lang.value)}
                  >
                    {lang.text}
                  </div>
                ))}
              </motion.div>
              <div className="header-search" onClick={openSearchSidebar}>
                <Image
                  className="header-search-icon"
                  src="/assets/icons/search.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div className="header-sections-sm">
            <div className="header-section-sm-left">
              {/* <a
                className="header-section-sm-left-item"
                href={`tel:${state.settings.headerPhone.value}`}
              >
                <Image
                  className="header-section-sm-left-item-icon-call"
                  src="/assets/icons/call.svg"
                  alt="icon"
                />
              </a>
              <a
                className="header-section-sm-left-item"
                href="https://goo.gl/maps/zYin1wnGAzCGm4826"
                target="blank"
              >
                <Image
                  className="header-section-sm-left-item-icon-pin"
                  src="/assets/icons/pin.svg"
                  alt="icon"
                />
              </a> */}
              <Image
                className="logo"
                src="/assets/images/logo-sm.svg"
                alt="logo"
                as="a"
                onClick={() => history.push('/')}
              />
            </div>
            <div className="header-section-sm-right">
              <div className="header-menu" onClick={openSidebar}>
                <Image
                  className="header-menu-icon"
                  src="/assets/icons/menu.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        animation="overlay"
        direction="right"
        visible={state.menu}
        className="menu-sidebar"
      >
        <div className="container">
          <div className="menu-sidebar-header">
            <div className="menu-sidebar-languages">
              <motion.a
                className="header-languages"
                animate={controlsLanguageSidebar}
                transition={{
                  duration: 0.5,
                  ease: 'easeInOut',
                }}
                onClick={changeLanguage}
              >
                <Image
                  className="header-languages-icon"
                  src="/assets/icons/arrow-right.svg"
                  alt="icon"
                  style={{
                    marginTop: 4,
                  }}
                />
                {languageOptions
                  .filter((data) => data.value === language)
                  .map((item, key) => (
                    <span key={key} className="header-languages-text">
                      {item.text}
                    </span>
                  ))}
              </motion.a>
              <motion.div
                className="header-languages-options"
                initial={{ display: 'none' }}
                animate={controlsLanguageOptionsSidebar}
                transition={{
                  duration: 0.5,
                  ease: 'easeInOut',
                }}
              >
                {languageOptions
                  .filter((data) => data.value !== language)
                  .map((lang, key) => (
                    <div
                      key={key}
                      className="header-languages-text"
                      style={
                        lang.value === language
                          ? { color: '#0B296C' }
                          : undefined
                      }
                      onClick={() => chooseLanguage(lang.value)}
                    >
                      {lang.text}
                    </div>
                  ))}
              </motion.div>
            </div>
            <div className="menu-sidebar-search-close">
              <div className="header-search" onClick={openSearchSidebar}>
                <Image
                  className="header-search-icon"
                  src="/assets/icons/search.svg"
                  alt="icon"
                />
              </div>
              <div className="header-sidebar-close" onClick={closeSidebar}>
                <Image
                  className="header-sidebar-close-icon"
                  src="/assets/icons/cancel.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <Menu vertical text className="menu-sidebar-list">
            {nav.map((item, key) => (
              <motion.div
                key={key}
                animate={controls}
                transition={{
                  duration: 0.7,
                  ease: 'easeInOut',
                }}
              >
                <Menu.Item
                  className="menu-sidebar-list-item"
                  name={item.name}
                  content={t(item.name)}
                  active={active === item.name}
                  onClick={() => handleClick(item)}
                />
              </motion.div>
            ))}
          </Menu>
          <div className="menu-sidebar-sosials">
            <a
              href={state.settings.facebook.value}
              target="blank"
              className="menu-sidebar-sosials-list-item"
            >
              <Image
                className="menu-sidebar-sosials-icon"
                src="/assets/icons/facebook.svg"
                alt="icon"
              />
            </a>
            <a
              href={state.settings.twitter.value}
              target="blank"
              className="menu-sidebar-sosials-list-item"
            >
              <Image
                className="menu-sidebar-sosials-icon"
                src="/assets/icons/twitter.svg"
                alt="icon"
              />
            </a>
            <a
              href={state.settings.linkedin.value}
              target="blank"
              className="menu-sidebar-sosials-list-item"
            >
              <Image
                className="menu-sidebar-sosials-icon"
                src="/assets/icons/linkedin.svg"
                alt="icon"
              />
            </a>
            <a
              href={state.settings.instagram.value}
              target="blank"
              className="menu-sidebar-sosials-list-item"
            >
              <Image
                className="menu-sidebar-sosials-icon"
                src="/assets/icons/instagram.svg"
                alt="icon"
              />
            </a>
          </div>
        </div>
      </Sidebar>
      <Search
        searchSidebar={state.searchSidebar}
        closeSearchSidebar={closeSearchSidebar}
      />
    </>
  );
};

export default Header;
