import React, { useState, useCallback, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import ImageCarousel from '../../components/ImageCarousel';
import { Image } from 'semantic-ui-react';
import { getAbout } from '../../modules/services';
import Gallery from '../../components/Gallery';
import Dots from '../../components/Dots';
import Loader from '../../components/Layout/Loader';
// @ts-ignore
import { Fade } from 'react-reveal';
import './index.scss';
import { useTranslation } from 'react-i18next';

let flag = true;

const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  const [about, setAbout] = useState({
    slides: [],
    content: {
      translation: {
        title: '',
        description: '',
        guideline_url: '',
        logomark_url: '',
      },
    },
    components: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flag && window.scrollTo(0, 0);
    flag = false;
  });

  const getAboutData = useCallback(() => {
    getAbout().then((response: any) => {
      setAbout(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getAboutData();
  }, [getAboutData]);

  const downloadFile = (url: string, name: string) => {
    let a = document.createElement('a');
    a.href = url;
    a.target = 'blank';
    a.download = `${name}.pdf`;
    a.click();
  };

  return (
    <>
      <Header />
      <Navbar />
      <Loader loading={loading}>
        <ImageCarousel images={about.slides} />
        <div className="about-page relative">
          <Fade bottom>
            <div className="container">
              <Title label={about.content.translation.title} position="none" />
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: about.content.translation.description,
                }}
              />
            </div>
          </Fade>
          <div className="about-page-components">
            {about.components.map((item: any, key) => (
              <div key={key} className="about-page-component">
                <Fade bottom>
                  <Title
                    label={item.translation.title}
                    position={key % 2 ? 'left' : 'right'}
                  />
                  <div className="container">
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: item.translation.text,
                      }}
                    />
                  </div>
                </Fade>
                {item.type === 'slider' ? (
                  <Fade>
                    <Gallery images={item.slides} />
                  </Fade>
                ) : null}
              </div>
            ))}
          </div>
          <Fade bottom>
            <div className="container">
              <div className="about-page-guideline">
                <div>
                  <div
                    className="about-page-guideline-button"
                    onClick={() =>
                      downloadFile(
                        about.content.translation.guideline_url,
                        'guidline'
                      )
                    }
                  >
                    <div className="about-page-guideline-button-general">
                      <Image src="/assets/icons/pdf.svg" alt="icon" />
                    </div>
                    <div className="about-page-guideline-button-hover">
                      <Image src="/assets/icons/download.svg" alt="icon" />
                      <span>{t('download')}</span>
                    </div>
                  </div>
                  <span className="about-page-guideline-button-info">
                    {t('brand_guidelines')}
                  </span>
                </div>
                <div>
                  <div
                    className="about-page-guideline-button"
                    onClick={() =>
                      downloadFile(
                        about.content.translation.logomark_url,
                        'logomark'
                      )
                    }
                  >
                    <div className="about-page-guideline-button-general">
                      <Image src="/assets/icons/pdf.svg" alt="icon" />
                    </div>
                    <div className="about-page-guideline-button-hover">
                      <Image src="/assets/icons/download.svg" alt="icon" />
                      <span>{t('download')}</span>
                    </div>
                  </div>
                  <span className="about-page-guideline-button-info">
                    {t('logomark')}
                  </span>
                </div>
              </div>
            </div>
          </Fade>
          <Dots
            row={4}
            column={7}
            color="#F2F2F2"
            opacity={1}
            top={80}
            right={0}
          />
          <Dots
            row={5}
            column={5}
            color="#3373B9"
            opacity={0.3}
            bottom={800}
            left={0}
          />
          <Dots
            row={5}
            column={5}
            color="#3373B9"
            opacity={0.3}
            bottom={0}
            right={0}
          />
        </div>
        <Footer />
      </Loader>
    </>
  );
};

export default AboutPage;
