import React from 'react';
import { Image } from 'semantic-ui-react';
import './index.scss';
import { AppContext } from '../../modules/contexts';
import LinkIcon from '../../assets/icons/LinkIcon';

type Props = {
  logo: string;
  cover: string;
  description: string;
  type: string;
  url: string;
};

const Partner: React.FC<Props> = (props) => {
  const { dispatch } = React.useContext(AppContext);

  const sidebarContent = (
    <div className="sidebar-content">
      <div className="partner-sidebar-cover">
        <Image src={props.cover} className="cover-image" alt="cover" />
        <div className="partner-sidebar-box">
          <Image className="partner-logo" src={props.logo} alt="logo" />
        </div>
      </div>
      <p className="sidebar-text sidebar-content-margin">{props.description}</p>
      <a className="link-box" href={props.url} target="blank">
        <LinkIcon />
      </a>
    </div>
  );

  const openSidebar = () => {
    dispatch({ type: 'OPEN_PARTNER_SIDEBAR' });
    dispatch({ type: 'ADD_SIDEBAR_CONTENT', payload: sidebarContent });
  };

  return (
    <div
      className={
        props.type === 'general' ? 'partner-box-general' : 'partner-box-home'
      }
      onClick={openSidebar}
    >
      <Image className="partner-logo" src={props.logo} alt="logo" />
    </div>
  );
};

export default Partner;
