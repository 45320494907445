import React, { createContext, useReducer } from 'react';
import { teamSidebarReducer, sidebarContentReducer, brandSidebarReducer, partnerSidebarReducer, menuSidebarReducer, searchSidebarReducer } from '../reducers/sidebar';
import { settingsReducer } from '../reducers/settings';
import { Settings } from '../models/index';
import { initialSettings } from '../constants/initialValues';

type InitialStateType = {
    teamSidebar: boolean;
    brandSidebar: boolean;
    partnerSidebar: boolean;
    siderbarContent: JSX.Element;
    settings: Settings;
    menu: boolean;
    searchSidebar: boolean;
}

const initialState = {
    teamSidebar: false,
    brandSidebar: false,
    partnerSidebar: false,
    siderbarContent: <div></div>,
    settings: initialSettings,
    menu: false,
    searchSidebar: false,
}

const AppContext = createContext<{
    state: InitialStateType;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState,
    dispatch: () => null
});

const mainReducer = ({
    teamSidebar,
    brandSidebar,
    partnerSidebar,
    siderbarContent,
    settings,
    menu,
    searchSidebar
}: InitialStateType, action: any) => ({
    teamSidebar: teamSidebarReducer(teamSidebar, action),
    brandSidebar: brandSidebarReducer(brandSidebar, action),
    partnerSidebar: partnerSidebarReducer(partnerSidebar, action),
    siderbarContent: sidebarContentReducer(siderbarContent, action),
    settings: settingsReducer(settings, action),
    menu: menuSidebarReducer(menu, action),
    searchSidebar: searchSidebarReducer(searchSidebar, action)
});

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
