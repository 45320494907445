import React from 'react';
import { Image } from 'semantic-ui-react';
import { AppContext } from '../../modules/contexts';
import LinkIcon from '../../assets/icons/LinkIcon';
import './index.scss';

type Props = {
  logo: string;
  cover: string;
  description: string;
  url: string;
};

const Brand: React.FC<Props> = (props) => {
  const { dispatch } = React.useContext(AppContext);

  const sidebarContent = (
    <div className="sidebar-content">
      <div className="brand-sidebar-cover">
        <Image src={props.cover} alt="cover" className="cover-image" />
        <div className="brand-sidebar-box">
          <Image className="brand-logo" alt="logo" src={props.logo} />
        </div>
      </div>
      <p className="sidebar-text sidebar-content-margin">{props.description}</p>
      <a className="link-box" href={props.url} target="blank">
        <LinkIcon />
      </a>
    </div>
  );

  const openSidebar = () => {
    dispatch({ type: 'OPEN_BRAND_SIDEBAR' });
    dispatch({ type: 'ADD_SIDEBAR_CONTENT', payload: sidebarContent });
  };

  return (
    <div className="brand-box" onClick={openSidebar}>
      <Image className="brand-logo" alt="logo" src={props.logo} />
    </div>
  );
};

export default Brand;
