import React, { SetStateAction, Dispatch } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Image } from 'semantic-ui-react';
import { IAccordion } from '../../modules/models';
import './index.scss';

type Props = {
  i: number;
  expanded: number | false;
  setExpanded: Dispatch<SetStateAction<number | false>>;
  data: IAccordion;
};

const Accordion: React.FC<Props> = (props) => {
  const isOpen = props.i === props.expanded;

  return (
    <>
      <div className="accordion-container">
        <motion.div
          className="accordion-header"
          initial={false}
          animate={{
            background: isOpen ? '#3373B9' : '#FFFFFF',
            color: isOpen ? '#FFFFFF' : '#3373B9',
          }}
          onClick={() => props.setExpanded(isOpen ? false : props.i)}
        >
          <div style={{ flex: 0.1 }} className="accordion-sm-hide" />
          <span style={{ flex: 1 }}>{props.data.translation?.title}</span>
          <div style={{ flex: 0.1 }} className="accordion-sm-hide">
            {isOpen && (
              <Image src="/assets/icons/accordion-arrow.svg" alt="icon" />
            )}
          </div>
        </motion.div>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              className="accordion-description"
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <motion.div
                variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
                transition={{ duration: 0.8 }}
                className="content-placeholder"
              >
                {props.data.translation?.text
                  .split('\n')
                  .map(function (item, idx) {
                    return item?.trim() && <span key={idx}>{item}</span>;
                  })}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Accordion;
