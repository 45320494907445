import React from 'react';
import Carousel from 'nuka-carousel';
import { Button, Image } from 'semantic-ui-react';
import { ISlides } from '../../modules/models';
import { useWindowInnerDimensions } from '../../hooks/dimension';
import './index.scss';

type Props = {
  images: ISlides[];
};

const ImageCarousel: React.FC<Props> = (props) => {
  const { width } = useWindowInnerDimensions();

  return (
    <Carousel
      style={{ background: props.images[0]?.color }}
      height={props.images.length ? '532px' : undefined}
      autoplay={props.images.length > 1 ? true : false}
      wrapAround={props.images.length > 1 ? true : false}
      cellAlign='center'
      disableEdgeSwiping
      disableAnimation
      renderCenterLeftControls={({ previousSlide }) =>
        width > 428 && props.images.length > 1 ? (
          <Button className='carousel-button' onClick={previousSlide}>
            <Image src='/assets/icons/arrow-left-blue.svg' alt='icon' />
          </Button>
        ) : null
      }
      renderCenterRightControls={({ nextSlide }) =>
        width > 428 && props.images.length > 1 ? (
          <Button className='carousel-button' onClick={nextSlide}>
            <Image src='/assets/icons/arrow-right-blue.svg' alt='icon' />
          </Button>
        ) : null
      }
      renderBottomCenterControls={null}
      defaultControlsConfig={{
        pagingDotsStyle: {
          fill: 'white',
        },
      }}
    >
      {props.images?.map((image, key) => {
        return (
          <img
            key={key}
            alt='background'
            src={width > 428 ? image.photo_url : image.mobile_photo_url}
            height='532px'
            style={{ objectFit: 'cover' }}
          />
        );
      })}
    </Carousel>
  );
};

export default ImageCarousel;
