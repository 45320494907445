import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';

type Props = {
  row: number;
  column: number;
  color: string;
  opacity: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

const Dots: React.FC<Props> = (props) => {
  const variants = {
    top: { y: 20 },
    bottom: { y: -20 },
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: props.top,
        bottom: props.bottom,
        left: props.left,
        right: props.right,
        zIndex: -100,
      }}
    >
      <motion.div
        variants={variants}
        initial="bottom"
        animate="top"
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 4,
        }}
      >
        <div className="dots">
          {[...Array(props.column)].map((e, key) => (
            <div key={key} className="dots-column">
              {[...Array(props.row)].map((e, key) => (
                <div
                  key={key}
                  className="dot"
                  style={{
                    backgroundColor: props.color,
                    opacity: props.opacity,
                  }}
                ></div>
              ))}
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Dots;
