import React from 'react';

function LinkIcon(props: any) {
  return (
    <span className="unitradeicon" {...props}>
      <svg width="37.761" height="37.761" viewBox="0 0 37.761 37.761">
        <g
          id="link_round_1110_"
          data-name="link_round [#1110]"
          transform="translate(18.88 18.88)"
        >
          <g
            id="link_round_1110_2"
            data-name="link_round [#1110]"
            transform="translate(-18.881 -18.88)"
          >
            <g id="Dribbble-Light-Preview">
              <g id="icons">
                <path
                  id="link_round-_1110_"
                  data-name="link_round-[#1110]"
                  d="M358.436,3122.325a11.353,11.353,0,0,0-16.057,0l-4.85,4.85,2.676,2.676,4.85-4.85a7.57,7.57,0,0,1,10.705,10.705l-4.85,4.851,2.676,2.676,4.85-4.851a11.354,11.354,0,0,0,0-16.057Zm-17.729,28.433a7.568,7.568,0,0,1-10.7-10.7l4.85-4.851-2.678-2.678-4.85,4.85a11.355,11.355,0,0,0,16.058,16.059l4.85-4.85-2.678-2.678Zm6.447-19.826,2.676,2.676-11.25,11.25-2.676-2.676Z"
                  transform="translate(-324 -3119)"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default LinkIcon;
