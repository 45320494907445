import React from 'react';
import './index.scss';

type Props = {
  label: string;
};

const Subtitle: React.FC<Props> = (props) => {
  return (
    <h2 className="subtitle">
      <span>{props.label}</span>
    </h2>
  );
};

export default Subtitle;
