import React from 'react';
import Carousel from 'nuka-carousel';
import { Button, Image } from 'semantic-ui-react';
import './index.scss';
import { useWindowDimensions } from '../../hooks/dimension';

type Statistic = {
  number: number;
  translation: {
    description: string;
  };
};

type Props = {
  statistics: Statistic[];
};

const TextCarousel: React.FC<Props> = (props) => {
  const { width } = useWindowDimensions();

  return (
    <div className="container" style={{ position: 'relative' }}>
      <Carousel
        wrapAround
        slidesToShow={width > 1224 ? 3 : 1}
        slidesToScroll={1}
        className="text-carousel"
        cellAlign="center"
        disableAnimation
        transitionMode="scroll"
        renderBottomCenterControls={null}
        renderCenterLeftControls={
          width > 1224
            ? ({ previousSlide }) => (
                <Button
                  className="text-carousel-button"
                  icon="chevron left"
                  onClick={previousSlide}
                  size="massive"
                />
              )
            : null
        }
        renderCenterRightControls={
          width > 1224
            ? ({ nextSlide }) => (
                <Button
                  className="text-carousel-button"
                  icon="chevron right"
                  onClick={nextSlide}
                  size="massive"
                />
              )
            : null
        }
        renderBottomLeftControls={
          width > 1224
            ? ({ nextSlide }) => (
                <div
                  onClick={nextSlide}
                  className="statistics-carousel-button-left"
                >
                  <Image src="/assets/icons/arrow-right-light.svg" alt="icon" />
                </div>
              )
            : null
        }
        renderBottomRightControls={
          width > 1224
            ? ({ nextSlide }) => (
                <div
                  onClick={nextSlide}
                  className="statistics-carousel-button-right"
                >
                  <Image src="/assets/icons/arrow-right-light.svg" alt="icon" />
                </div>
              )
            : null
        }
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: 'white',
          },
        }}
      >
        {props.statistics?.map((statistic, key) => (
          <div key={key}>
            <h5 key={key} className="statistic-number">
              {statistic.number}
            </h5>
            <h6 className="statistic-title">
              {statistic.translation.description}
            </h6>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TextCarousel;
