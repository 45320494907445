import React from 'react';
import { Menu, Image } from 'semantic-ui-react';
import './index.scss';
import { history } from '../../utils/history';
import { useTranslation } from 'react-i18next';

const nav1 = [
  { name: 'aboutUs', url: '/about' },
  { name: 'brands', url: '/brands' },
  { name: 'partners', url: '/partners' },
];

const nav2 = [
  { name: 'careers', url: '/careers' },
  { name: 'news', url: '/news' },
  { name: 'contacts', url: '/contacts' },
];

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const [active, setActive] = React.useState('');

  const handleClick = (item: { name: string; url: string }) => {
    history.push(item.url);
    setActive(item.name);
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-list">
          <Menu text className="navbar-list-first">
            {nav1.map((item, key) => (
              <Menu.Item
                key={key}
                className="navbar-list-item"
                name={item.name}
                content={t(item.name)}
                active={active === item.name}
                onClick={() => handleClick(item)}
              />
            ))}
          </Menu>
          <Image
            className="navbar-logo"
            src="/assets/images/logo.svg"
            alt="logo"
            as="a"
            onClick={() => history.push('/')}
          />
          <Menu text className="navbar-list-second">
            {nav2.map((item, key) => (
              <Menu.Item
                key={key}
                className="navbar-list-item"
                name={item.name}
                content={t(item.name)}
                active={active === item.name}
                onClick={() => handleClick(item)}
              />
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
